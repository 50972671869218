import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <p>404: Not Found</p>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export const Head = () => (
  <Seo
    title="Megamoon"
    description="Megamoon is the leading decentralized Lottery on Polygon Chain, with the highest volumes in the market and Megamoon exchange : Earn crypto and Trade crypto"
    keywords="Megamoon, Decentralized Lottery, Polygon Chain, Megamoon Lottery, Megamoon exchange, Earn crypto, Trade crypto, Win crypto"
  />
)

export default NotFoundPage
